<template>
    <v-container fluid>
        <v-alert
                dense
                :type="alert.type"
                timeout="5000"
                v-if="alert.message">
            {{ alert.message }}
        </v-alert>
        <v-card>
            <v-card-title>
                <p>
                    <v-btn
                            color="primary"
                            @click.stop="openSM();"
                            class="mx-1"
                    >
                        Create
                        <v-icon
                                dark
                                right
                        >
                            mdi-plus
                        </v-icon>
                    </v-btn>
                    <v-btn
                            color="green"
                            @click="fetchData()"
                            class="mx-1"
                    >
                        Refresh
                        <v-icon
                                dark
                                right
                        >
                            mdi-refresh
                        </v-icon>
                    </v-btn>
                    <v-btn
                            color="orange"
                            @click="doForceLogin(false)"
                            class="mx-1"
                    >
                        Force all seller re-login
                        <v-icon
                                dark
                                right
                        >
                            mdi-refresh
                        </v-icon>
                    </v-btn>
                    <v-row>
                        <v-col cols="auto" class="mx-1">
                            <v-checkbox
                                v-model="menu.packages"
                                label="Show Packages"
                            />
                        </v-col>
                        <v-col cols="auto" class="mx-1">
                            <v-checkbox
                                v-model="menu.shop"
                                label="Show Shop"
                            />
                        </v-col>
                        <v-col cols="auto" class="mx-1">
                            <v-checkbox
                                v-model="menu.test_key"
                                label="Show TestKey"
                            />
                        </v-col>
                    </v-row>
                </p>
                <v-spacer></v-spacer>
                Seller List
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        label="Search"
                        single-line
                        hint="Please enter keyword and click search icon"
                        persistent-hint
                >
                    <template v-slot:append>
                        <v-btn color="cyan" 
                            elevation="2"
                            icon
                            dark
                            :disabled="Overlay"
                            :loading="Overlay"
                            @click="doSearch()"
                        >
                            <v-icon>
                                mdi-magnify
                            </v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-card-title>
            <v-data-table
                    :headers="headers"
                    :items="itemsWithIndex"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :loading="Overlay"
                    loading-text="Loading... Please wait"
                    :footer-props="{'items-per-page-options':[15,30,50,100]}"
                    :items-per-page="30"
            >
                <template v-slot:[`item.plans`]="{ item }">
                    <template v-if="plans[item.seller_id]">
                        <v-list
                            class="mb-2"
                            style="max-height: 220px; overflow-y: auto;"
                        >
                            <v-list-item
                                    v-for="plan in plans[item.seller_id]"
                                    :key="plan['planId']"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div class="mb-1 mt-1">
                                            <span class="orange--text">{{plan['planName']}}</span>
                                        </div>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <div class="mb-1">
                                            <span class="green--text">Level {{plan['planLevel']}}</span>
                                        </div>
                                        <div class="mb-1">
                                            <span class="blue--text">Package: {{plan['planPackage']}} Days</span>
                                        </div>
                                        <div class="mb-1">
                                            <span class="green--text">Price: ${{plan['planPrice']}}</span>
                                        </div>
                                        <div class="mb-1">
                                            <v-icon v-if="plan.planMultiHWID">
                                                mdi-monitor-multiple
                                            </v-icon>
                                            <v-icon v-else>
                                                mdi-monitor
                                            </v-icon>
                                        </div>
                                        <div class="mb-1">
                                            <span class="blue--text">
                                                <template v-if="plan['planCountry'] === 'Global'">
                                                        {{plan['planCountry']}}
                                                </template>
                                                <template v-else>
                                                    <gb-flag
                                                            :code="plan['planCountry'].toString().toLowerCase()"
                                                            size="mini"
                                                    />
                                                </template>
                                            </span>
                                        </div>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                    <template v-else>
                        <span class="red--text">
                                        NO PLAN
                        </span>
                    </template>
                    <p>
                        <v-btn
                            color="orange"
                            @click="toggleBoxPlan(item)"
                            dark
                            small
                            class="mr-2"
                        >
                            Modify Plan
                        </v-btn>
                        <v-btn
                            color="purple"
                            @click="toggleCloneSellerPlan(plans[item.seller_id])"
                            dark
                            small
                            v-if="plans[item.seller_id]"
                        >
                            Clone Plan
                        </v-btn>
                    </p>
                </template>
                <template v-slot:[`item.seller_id`]="{ item }">
                    <v-btn
                            text
                            color="cyan"
                            small
                    >
                        {{item.seller_id}}
                    </v-btn>
                </template>
                <template v-slot:[`item.stats`]="{ item }">
                        <div v-for="(data,index) in item.stats" :key="index" :class="`${index === 0 ? 'mt-5':'mt-3'}`">
                            <v-badge
                                    color="#0B5DEF"
                                    :content="data.toString()"
                            >
                                <div
                                    class="orange--text"
                                >
                                    <v-icon
                                            dark
                                            left
                                            color="orange"
                                    >
                                        mdi-key
                                    </v-icon>
                                    {{statsHeaders[index]}}
                                </div>
                            </v-badge>
                        </div>
                </template>
                <template v-slot:[`item.username`]="{ item }">
                    <v-btn
                            text
                            @click.stop="openSM(item);"
                            small
                            dark
                    >
                        <v-icon
                                light
                                left
                                v-if="item.enableTopup"
                                color="yellow darken-2"
                        >
                            mdi-crown
                        </v-icon>
                        <v-icon dark left color="green" v-if="item.enableRefund">
                            mdi mdi-cash-refund
                        </v-icon>
                        <template v-if="item.status">{{item.username}}</template>
                        <template v-else><strike>{{item.username}}</strike></template>
                        <v-icon
                                dark
                                right
                                color="indigo accent-2"
                                v-text="(item.status) ? 'mdi-pencil':'mdi-pencil-lock'"
                        >
                        </v-icon>
                    </v-btn>
                    <template v-if="item.bonus > 0">
                        <p>
                            <span style="color:#0074FE">Topup</span> <strong>{{item.bonus}}</strong> %
                        </p>
                    </template>
                    <v-autocomplete
                        v-model="item.linked"
                        :items="sellersLists"
                        item-text="username"
                        item-value="username"
                        label="Account Not Linked"
                        single-line
                        v-on:change="doUpdateLink(item)"
                        autowidth
                        class="pt-2 mt-2"
                        multiple
                    />
                    <template v-if="item.linked">
                        <v-btn
                                x-small
                                color="orange lighten-2"
                                @click="doUpdateLink(item,true)"
                        >
                            Remove Link
                        </v-btn>
                    </template>
                    <div class="mt-2">
                        Created At: {{item.created_at}}
                    </div>
                    <br />
                    <div
                            class="mt-2"
                    >
                        <v-btn
                                color="red"
                                dark
                                small
                                class="mx-1"
                                @click="doLockAllKeyBySellerID(item)"
                        >
                            Lock Keys
                        </v-btn>
                        <v-btn
                                color="green"
                                dark
                                small
                                class="mx-1"
                                @click="doUnlockAllKeyBySellerID(item)"
                        >
                            Unlock Keys
                        </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="deep-orange darken-1"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                    >
                                        More Action
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn
                                                color="red"
                                                dark
                                                small
                                                class="mx-1"
                                                @click="doLoadRemoveSeller(item)"
                                            >
                                                REMOVE SELLER
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn
                                                    color="orange dark-1"
                                                    dark
                                                    small
                                                    class="mx-1"
                                                    @click="doForceLogin(item.seller_id)"
                                            >
                                                FORCE SELLER RE-LOGIN
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-checkbox
                                                v-model="item['limit_reset']"
                                                :label="item['limit_reset'] ? `Limited Reset`:`Unlimited Reset HWID`"
                                                color="yellow darken-2"
                                                @change="doUpdateLimitReset(item)"
                                            />
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-checkbox
                                                v-model="item['limit_unbind']"
                                                :label="item['limit_unbind'] ? `Limited Unbind`:`Unlimited Unbind`"
                                                color="yellow darken-2"
                                                @change="doUpdateLimitUnbind(item)"
                                            />
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-checkbox
                                                v-model="item['allow_refund_renew']"
                                                :label="item['allow_refund_renew'] ? `Allowed to Refund on renew`:`Disallow to Refund renew`"
                                                :color="item['allow_refund_renew'] ? 'yellow darken-2':'orange darken-2'"
                                                @change="doUpdateRefundOnRenew(item)"
                                            />
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-checkbox
                                                v-model="item['raw_hwid']"
                                                :label="item['raw_hwid'] ? `Raw HWID`:`Encrypted HWID`"
                                                :color="item['raw_hwid'] ? 'yellow darken-2':'orange darken-2'"
                                                @change="doUpdateRawHWID(item)"
                                            />
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                    </div>
                </template>
                <template v-slot:[`item.balance`]="{ item }">
                    <v-btn
                            color="success"
                            text
                            small
                            @click="topupSeller(item,'topup')"
                    >
                        <v-icon
                                light
                                left
                        >
                            mdi-wallet
                        </v-icon>
                        ({{item.balance}})
                    </v-btn>
                </template>
                <template v-slot:[`item.testkeys`]="{ item }">
                    <v-btn
                            text
                            small
                            @click="topupSeller(item,'addTestKeys')"
                    >
                        <v-icon
                                light
                                left
                        >
                            mdi-key
                        </v-icon>
                        ({{item.testkeys}})
                    </v-btn>
                    <br>
                    <v-switch
                            :value="item.enableTest"
                            :input-value="item.enableTest"
                            :success="item.enableTest"
                            :error="!item.enableTest"
                            :label="item.enableTest ? 'Enable':'Disable'"
                            @change="doEnableTest(item.seller_id,!item.enableTest)"
                    />
                </template>
                <template v-slot:[`item.price`]="{ item }">
                    <v-simple-table
                            fixed-header
                            height="200px"
                    >
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    <h3>Package</h3>
                                </th>
                                <th class="text-left">
                                    <h3>Price</h3>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    v-for="(data,index) in item.price"
                                    :key="index"
                            >
                                <td class="text-left">{{ packages[index] }}</td>
                                <td class="text-left" v-if="data == -1">
                                    <v-btn
                                            class="ma-2"
                                            color="red"
                                            dark
                                            x-small
                                            text
                                    >
                                        Disabled
                                        <v-icon
                                                dark
                                                right
                                        >
                                            mdi-lock
                                        </v-icon>
                                    </v-btn>
                                </td>
                                <td class="text-left" v-if="data != -1">
                                    <v-btn
                                            class="ma-2"
                                            color="green"
                                            dark
                                            x-small
                                            text
                                    >
                                        {{data}}
                                        <v-icon
                                                dark
                                                right
                                        >
                                            mdi-currency-usd
                                        </v-icon>
                                    </v-btn>
                                </td>

                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
                <template v-slot:[`item.linked`]="{ item }">

                </template>
                <template v-slot:[`item.shop_seller`]="{ item }">
                    <v-btn
                            color="green"
                            @click="doSelectSeller(item)"
                    >
                        SET SHOP PRICE
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialog"
                  transition="dialog-bottom-transition"
                  max-width="600"
        >
            <validation-observer
                    ref="observer"
                    v-slot="{invalid }">
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="doSellerRequest">
                    <v-card>
                        <v-toolbar
                                color="primary"
                                dark
                        >SELLER MANAGER</v-toolbar>
                        <v-card-text>
                            <div class="text-h2 pa-12">
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Username"
                                        rules="required|min:4"
                                >
                                    <v-text-field v-model="itemSeller.username" :error-messages="errors">
                                        <v-icon
                                                slot="prepend"
                                        >
                                            mdi-account
                                        </v-icon>
                                    </v-text-field>
                                </validation-provider>
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Password"
                                        rules="required|min:4"
                                >
                                    <v-text-field v-model="itemSeller.password" :error-messages="errors">
                                        <v-icon
                                                slot="prepend"
                                        >
                                            mdi-lock
                                        </v-icon>
                                    </v-text-field>
                                </validation-provider>
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="Bonus"
                                        rules="required|min:1|between:0,100"
                                >
                                    <v-text-field v-model="itemSeller.bonus" label="Bonus (%)" :error-messages="errors">
                                        <v-icon
                                                slot="prepend"
                                        >
                                            mdi-percent
                                        </v-icon>
                                    </v-text-field>
                                </validation-provider>
                                <v-item-group>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    v-for="(data, index) in itemSeller.price"
                                                    :key="index"
                                                    cols="12"
                                                    md="4"
                                            >
                                                <v-item>
                                                    <v-text-field v-model="itemSeller.price[index]" v-bind:label="packages[index]"
                                                                  hint="Disabled: -1"
                                                                  persistent-hint
                                                                  :rules="[v => !!v || 'Price is required']"
                                                    >
                                                        <v-icon
                                                                slot="prepend"
                                                        >
                                                            mdi-wallet
                                                        </v-icon>
                                                    </v-text-field>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-item-group>
                                <v-row>
                                    <v-col
                                            cols=5
                                    >
                                        <v-checkbox v-model="itemSeller.enableTopup" value label="Enable Topup ?" />
                                    </v-col>
                                    <v-col
                                            cols=5
                                    >
                                        <v-checkbox v-model="itemSeller.enableRefund" value label="Enable Refund ?" />
                                    </v-col>
                                </v-row>
                                <v-btn-toggle borderless>

                                    <v-btn value="left" color="primary" type="submit" v-if="!modifyMode" :disabled="invalid">
                                        <span class="hidden-sm-and-down">Create</span>
                                        <v-icon right>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>

                                    <v-btn value="left" color="primary" type="submit" v-if="modifyMode" :disabled="invalid">
                                        <span class="hidden-sm-and-down">Update</span>
                                        <v-icon right>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                    <v-btn value="center" color="orange" v-if="!itemSeller.status && modifyMode" @click="doSellerStatus(itemSeller.seller_id,1)">
                                        <span class="hidden-sm-and-down">UnBan</span>
                                        <v-icon right>
                                            mdi-account-check
                                        </v-icon>
                                    </v-btn>
                                    <v-btn value="center" color="error" v-if="itemSeller.status" @click="doSellerStatus(itemSeller.seller_id,0)">
                                        <span class="hidden-sm-and-down">Ban</span>
                                        <v-icon right>
                                            mdi-account-cancel
                                        </v-icon>
                                    </v-btn>

                                    <v-btn value="right" color="deep-orange" v-if="itemSeller.twofa" @click="doRemove2FA(itemSeller.seller_id)">
                                        <span class="hidden-sm-and-down">Remove 2FA</span>
                                        <v-icon right>
                                            mdi-google
                                        </v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                            <Loading :status="Overlay" />
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                    text
                                    @click="dialog = false"
                            >Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </validation-observer>
        </v-dialog>
        <v-overlay :value="Overlay">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <v-menu
                v-model="topupMenu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
                dark
        >
            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="topupMethod==='topup'">Add Balance: {{topupSellerData.username}}</v-list-item-title>
                            <v-list-item-title v-if="topupMethod==='addTestKeys'">Add Test Key: {{topupSellerData.username}}</v-list-item-title>
                            <v-list-item-subtitle>How much you want to add ?</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-list>
                    <v-list-item>
                        <v-text-field v-model="topupSellerData.credit" label="Amount">
                            <v-icon
                                    slot="prepend"
                                    v-if="topupMethod==='topup'">
                                mdi-wallet
                            </v-icon>
                            <v-icon
                                    slot="prepend"
                                    v-if="topupMethod==='addTestKeys'">
                                mdi-key
                            </v-icon>
                        </v-text-field>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-textarea v-model="topupSellerData.log" label="Log">
                            <v-icon
                                    slot="prepend"
                                    v-if="topupMethod==='topup'"
                            >
                                mdi-wallet
                            </v-icon>
                        </v-textarea>
                    </v-list-item>
                </v-list>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            text
                            @click="topupMenu = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            color="primary"
                            text
                            @click="doTopup"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
        <v-dialog
                v-model="boxSeller"
                persistent
                max-width="900px"
        >
            <v-card flat>
                <v-card-title>SET PRICE SHOP</v-card-title>
                <v-card-text>
                    <v-select
                            v-model="selectedSeller"
                            :items="SellerList"
                            item-text="username"
                            item-value="userid"
                            label="Seller"
                    />
                    <template v-if="choiceSeller">
            <span
                    v-for="seller in system['shop_seller']"
                    :key="`${choiceSeller.seller_id}${seller}`"
            >
                    <template v-if="selectedSeller === seller">
                      <p class="block text-h6">Price for {{searchById(seller)[0]['username']}}</p>
                      <v-row>
                        <v-col
                                cols="auto"
                        >
                          <v-text-field
                                  v-model="choiceSeller['shop_seller'][seller]['2HOUR']"
                                  label="TEST KEY"
                                  hint="Price (-1 or blank disabled)"
                                  persistent-hint
                          />
                        </v-col>
                        <v-col
                                cols="auto"
                        >
                          <v-text-field
                                  v-model="choiceSeller['shop_seller'][seller]['7DAY']"
                                  label="7DAY"
                                  hint="Price (-1 or blank disabled)"
                                  persistent-hint
                          />
                        </v-col>
                        <v-col
                                cols="auto"
                        >
                          <v-text-field
                                  v-model="choiceSeller['shop_seller'][seller]['30DAY']"
                                  label="30DAY"
                                  hint="Price (-1 or blank disabled)"
                                  persistent-hint
                          />
                        </v-col>
                      </v-row>
                      <br />
                    </template>
                  </span>
                    </template>
                    <Loader :status="updateSellerState" />
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            dark
                            :disabled="!selectedSeller || updateSellerState"
                            :loading="updateSellerState"
                            small
                            color="green"
                            @click="doUpdateShopPrice()"
                    >
                        Save
                    </v-btn>
                    <v-spacer />
                    <v-btn
                            dark
                            @click="boxSeller = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <v-dialog
        v-model="remove_seller_data.state"
        persistent
        max-width="600px"
    >
        <v-card>
            <v-card-title>
                <span class="headline">Remove Seller: {{remove_seller_data.name}}</span>
            </v-card-title>
            <v-card-text>
                <v-select
                    v-model="remove_seller_data.remove_mode"
                    :items="remove_mode"
                    item-text="text"
                    item-value="id"
                    label="Remove Mode"
                    v-on:change="doUpdateRemoveMode"
                    :hint="remove_mode_hint[remove_seller_data.remove_mode]"
                />
                <tempate v-if="remove_seller_data.remove_mode >= 5">
                    <v-autocomplete
                        v-model="remove_seller_data.moved_id"
                        :items="items"
                        item-text="username"
                        item-value="seller_id"
                        label="Moved To (Dest Seller)"
                        single-line
                        v-on:change="doUpdateMovedSellerTo"
                        autowidth
                        class="pt-2 mt-2"
                    />
                </tempate>
            </v-card-text>
            <v-card-actions>
                <v-btn
                 color="blue darken-1"
                 @click="doClearRemoveSeller()"
                >
                    Close
                </v-btn>
                <v-spacer />
                <v-btn
                 color="red darken-1"
                 @click="doSubmitRemoveSeller()"
                 :loading="Overlay"
                 :disabled="Overlay || !remove_seller_data.seller_id || (remove_seller_data.remove_mode >= 5 && !remove_seller_data.moved_id)"
                >
                    Remove
                </v-btn>
            </v-card-actions>
            <v-overlay :value="Overlay">
                <v-progress-circular
                 indeterminate
                 size="64"
                />
            </v-overlay>
        </v-card>
    </v-dialog>
    <v-dialog
        v-model="boxPlan"
        persistent
        fullscreen
    >
        <v-card>
            <v-card-title>
                <span class="headline">Modify Plan for Seller: {{modifySellerName}}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="searchPlan"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    clearable
                    class="mb-1"
                />
                <div style="max-height: 60vh; overflow-y: auto;">
                    <v-data-table
                        :headers="headersPlan"
                        :items="modifyPlanList"
                        :items-per-page="15"
                        :search="searchPlan"
                        class="elevation-1"
                        :footer-props="{
                            'items-per-page-options': [15, 20, 25, { 'text': '$vuetify.dataIterator.rowsPerPageAll', 'value': -1 }]
                        }"
                    >
                        <template v-slot:[`item.planPackage`]="{ item }">
                            {{`${item.planPackage} Day(s)`}}
                        </template>
                        <template v-slot:[`item.planMultiHWID`]="{ item }">
                            <v-icon v-if="item.planMultiHWID">
                                mdi-monitor-multiple
                            </v-icon>
                            <v-icon v-else>
                                mdi-monitor
                            </v-icon>
                        </template>
                        <template v-slot:[`item.planCountry`]="{ item }">
                            <template v-if="item.planCountry === 'Global'">
                                {{ item.planCountry }}
                            </template>
                            <gb-flag
                             :code="item.planCountry.toLowerCase()"
                             size="mini"
                             v-else
                            />
                        </template>
                        <template v-slot:[`item.planName`]="{ item }">
                            {{ item.planName }}
                            <div>
                                <v-btn
                                    small
                                    color="primary"
                                    @click="toggleModifyPlan(item)"
                                    icon
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    small
                                    color="red"
                                    @click="doDeletePlan(item)"
                                    icon
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>
                </div>
            </v-card-text>
            <v-card-text>
                <v-stepper
                 v-model="stepPlan"
                 vertical
                >
                    <v-stepper-step
                     :complete="stepPlan > 1"
                     step="1"
                    >
                        Please select Plan and Package
                        <v-stepper-content step="1">
                            <v-row>
                                <v-col
                                 cols="12"
                                 sm="6"
                                 md="4"
                                >
                                    <v-select
                                     v-model="planData.planLinked"
                                     :items="planList"
                                     item-text="name"
                                     item-value="id"
                                     label="Plan"
                                    />
                                </v-col>
                                <v-col
                                 cols="12"
                                 sm="6"
                                 md="4"
                                >
                                    <v-select
                                     v-model="planData.planPackage"
                                     :items="packageList"
                                     item-text="name"
                                     item-value="id"
                                     label="Package"
                                    />
                                </v-col>
                            </v-row>
                            <div
                                class="ma-1"
                            >
                                <v-btn
                                 color="primary"
                                 @click="stepPlan = 2"
                                 :disabled="!planData.planLinked || !planData.planPackage"
                                >
                                    Next
                                </v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-step>
                    <v-stepper-step
                     :complete="stepPlan > 2"
                     step="2"
                    >
                        Please provide information for Plan
                        <v-stepper-content step="2">
                            <v-row>
                                <v-col
                                 cols="12"
                                >
                                    <div class="orange--text">
                                        System Plan Linked: {{selectedPlan}}
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <div class="blue--text">
                                        System Plan Package: {{selectedPackage}}
                                    </div>
                                </v-col>
                                <v-col
                                 cols="12"
                                >
                                    <v-text-field
                                     v-model="planData.planName"
                                     label="Plan Name"
                                    />
                                </v-col>
                                <v-col
                                 cols="12"
                                >
                                    <v-text-field
                                     v-model="planData.planPrice"
                                     label="Plan Price"
                                    />
                                </v-col>
                                <v-col
                                 cols="12"
                                >
                                    <v-autocomplete
                                     v-model="planData.planCountry"
                                     :items="Countries"
                                     label="Country"
                                     rounded
                                     solo
                                     dark
                                    />
                                </v-col>
                                <v-col
                                 cols="12"
                                >
                                    <v-checkbox
                                     v-model="planData.planMultiHWID"
                                     class="ma-2"
                                    >
                                        <template v-slot:label>
                                            <template v-if="planData.planMultiHWID">
                                                <v-icon>
                                                    mdi-monitor-multiple
                                                </v-icon>
                                                <span class="ma-1">2 HWID</span>
                                            </template>
                                            <template v-else>
                                                <v-icon>
                                                    mdi-monitor
                                                </v-icon>
                                                <span class="ma-1">1 HWID</span>
                                            </template>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <p>
                                <v-btn
                                 color="primary"
                                 @click="stepPlan = 1"
                                 class="mx-1"
                                >
                                    Back
                                </v-btn>
                                <v-btn
                                 color="green"
                                 @click="doModifyPlan"
                                 class="mx-1"
                                 :disabled="!planData.planName || !planData.planPrice || !planData.planCountry || Overlay"
                                 :loading="Overlay"
                                >
                                    {{ planData.planId ? 'Update' : 'Create' }}
                                </v-btn>
                            </p>
                        </v-stepper-content>
                    </v-stepper-step>
                </v-stepper>
            </v-card-text>
            <v-card-actions>
                <v-btn
                 color="blue darken-2"
                 @click="toggleBoxPlan(false)"
                 class="mx-1"
                 v-if="planData.planId"
                >
                    Reset
                </v-btn>
                <v-spacer />
                <v-btn
                 color="blue darken-1"
                 @click="boxPlan = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
            <v-overlay :value="Overlay">
                <v-progress-circular
                 indeterminate
                 size="64"
                />
            </v-overlay>
        </v-card>
    </v-dialog>
    <ClonePlan ref="cloneplan" :sellers="sellersLists" @cloned="handleClonedCompleted" />
    </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { required, digits, min, max, regex,between } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import Loading from '../components/Loading.vue'
import Loader from '../components/Loader.vue'
import Alert from '../helpers/notice';
import Notice from "../helpers/notice";
import CountryJson from '../json/countries.json';
import LocalStorage from "../helpers/localStorage";
import ClonePlan from "../components/CloneSellerPlan.vue"
setInteractionMode('eager')
extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})
extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})
extend('min', {
    ...min,
    message: '{_field_}  must be {length} or more',
})
extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})
extend('between', {
    ...between,
    message: '{_field_} need from 0-100% ({_value_})',
})
extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})
export default {
    name: 'Seller',
    components: {
        ValidationProvider,
        ValidationObserver,
        Loading,
        Loader,
        ClonePlan,
    },
    data : () => ({
        totalItems: 0,
        remove_seller_data:{
            state: false,
            seller_id: null,
            name: null,
            remove_mode: null,
            moved_id: null,
        },
        updateSellerState: false,
        boxSeller: false,
        choiceSeller: null,
        valid: false,
        modifyMode: false,
        Overlay: false,
        dialog: false,
        search: '',
        options:{
            sortBy: ["seller_id"]
        },
        selectedSeller: null,
        headersPlan:[
            { text: 'Name', align: 'center', sortable: true,value: 'planName',width:"auto" },
            { text: 'Level', align: 'center', sortable: true,value: 'planLevel',width:"auto" },
            { text: 'Package', align: 'center', sortable: true,value: 'planPackage',width:"auto" },
            { text: 'Price', align: 'center', sortable: true,value: 'planPrice',width:"auto" },
            { text: 'Country', align: 'center', sortable: false,value: 'planCountry',width:"auto" },
            { text: 'HWID', align: 'center', sortable: false,value: 'planMultiHWID',width:"auto" },
        ],
        statsHeaders:[
            "Total keys",
            "Unused  keys",
            "Used keys",
            "Expired keys",
            "Online"
        ],
        items: [],
        updateMode: false,
        itemSeller: {},
        packages:['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
        topupMenu: false,
        topupSellerData: {},
        topupMethod: "",
        invalid: true,
        errors: "",
        system: {
            shop_seller: [],
        },
				    plans: [],
				    all_plans: [],
				    boxPlan: false,
        stepPlan: "1",
				    planData:{
            planSeller: "",
				        planName: "",
				        planPackage: "",
				        planPrice: "",
				        planCountry: "Global",
            planLinked: null,
            planMultiHWID: false,
				    },
        planPackage:{},
        searchPlan: '',
        menu:{
            packages: false,
            shop: false,
            test_key: false,
        },
        sellersLists: [],
    }),
    computed: {
        ...mapState('auth', ['status']),
        ...mapState({
            alert: state => state.alert
        }),
        headers(){
            let menu = [
                { text: 'Seller ID', align: 'center', sortable: true,value: 'seller_id',width:"auto" },
                { text: 'User Data', align: 'left', sortable: false,value: 'username',width:"auto" },
                { text: 'Credits', align: 'center', sortable: true,value: 'balance',width:"auto" },
                { text: 'Test Keys', align: 'center', sortable: true,value: 'testkeys',width:"auto" },
                { text: 'Packages', align: 'center', sortable: false,value: 'price',width:"auto" },
                { text: 'Shop', align: 'center', sortable: false,value: 'shop_seller',width:"auto" },
                { text: 'Plans', align: 'center', sortable: false,value: 'plans',width:"auto" },
                { text: 'Stats', align: 'left', sortable: false,value: 'stats',width:"auto" },
            ];
            if(!this.menu.packages && !this.menu.shop && !this.menu.test_key) menu.splice(3,3);
            else if(!this.menu.packages) menu.splice(4,1);
            else if(!this.menu.shop) menu.splice(5,1);
            else if(!this.menu.test_key) menu.splice(3,1);
            return menu;
        },
        Countries(){
            return [{value:'Global',text:'Global'}].concat(Object.keys(CountryJson).map((key) => {
                return {
                    value: key,
                    text: CountryJson[key]
                };
            }));
        },
        modifyPlanList(){
            const sellerId = this.planData['planSeller'];
          if(this.plans[sellerId]) return this.plans[sellerId];
          return [];
        },
        selectedPlan(){
          if(!this.planData['planLinked']) return "NO PLAN LINKED";
          return "LEVEL " + this.all_plans.filter(plan=>plan['planId'] === this.planData['planLinked'])[0]['planLevel'];
        },
        selectedPackage(){
            if(!this.planData['planPackage']) return "NO PACKAGE SELECTED";
            return this.planData['planPackage'] + " DAY(s)";
        },
        planList(){
          return this.all_plans.map(plan=>({
            id: plan['planId'],
            name: `Level ${plan['planLevel']}`,
          }));
        },
        packageList(){
          if(!this.planData['planLinked']) return [];
          return this.all_plans.filter(plan=>plan['planId'] === this.planData['planLinked'])[0]['planPackages'].map(plan_package=>({
            id: plan_package,
            name: `${plan_package} DAY(s)`,
          }));
        },
        modifySellerName(){
          if(!this.planData['planSeller']) return "";
          let seller = this.searchById(this.planData['planSeller']);
          return seller[0]['username'];
        },
        itemsWithIndex(){
            return this.items.map((item,index)=>({
                ...item,
                index:index,
            }));
        },
        SellerList(){
            if(!this.system['shop_seller'] || !Array.isArray(this.system['shop_seller']) || this.system['shop_seller'].length <=0) return [];
            if(this.sellersLists.length <= 0 ) return [];
            return this.system['shop_seller'].map(seller=>{
                let found_seller = this.searchById(parseInt(seller));
                if (found_seller[0]){
                    return {
                        userid: seller,
                        username:found_seller[0]['username'],
                    }
                }
            }).filter(seller=>seller && seller.username);
        },
        remove_mode(){
            return [
                {id: 1, text: 'REMOVE WITHOUT DELETE KEY'},
                {id: 2, text: 'REMOVE AND DELETE KEY CREATED BY'},
                {id: 3, text: 'REMOVE AND DELETE KEY PURCHASED BY'},
                {id: 4, text: 'REMOVE AND DELETE ALL KEY'},
                {id: 5, text: 'REMOVE AND MOVE ALL CREATED KEY TO CREATED KEY'},
                {id: 6, text: 'REMOVE AND MOVE ALL PURCHASED KEY TO PURCHASED KEY'},
                {id: 7, text: 'REMOVE AND MOVE ALL KEY CREATED TO CREATED KEY - PURCHASED TO PURCHASED KEY'},
                {id: 8, text: 'REMOVE AND MOVE ALL KEY TO CREATED KEY'},
                {id: 9, text: 'REMOVE AND MOVE ALL KEY TO PURCHASED KEY'},
            ]
        },
        remove_mode_hint(){
            return [
                "",
                "This action will remove seller only and keep seller's key",
                "This action will remove seller and seller's created key - keep seller's purchased key",
                "This acction will remove seller and seller's purchased key - keep seller's created key",
                "This action will remove seller and seller's key",
                "This action will remove seller and move seller's created key to dest seller created key",
                "This action will remove seller and move seller's purchased key to dest seller purchased key",
                "This action will remove seller and move seller's created key to dest seller created key - seller's purchased key to desc seller purchased key",
                "This action will remove seller and move all seller's key to dest seller created key",
                "This action will remove seller and move seller's created key to dest seller purchased key",
            ]
        }
    },
    methods: {
        ...mapActions('auth', ['login', 'logout']),
        ...mapActions('alert', ['success', 'error']),
        toggleCloneSellerPlan(plans){
            this.$refs.cloneplan.open(plans);
        },
        toggleChangeMenu(){
            LocalStorage.Save('menuSeller', this.menu);
        },
        doSearch(){
            let search = {
                ...this.options,
                q: this.search
            };
            return this.fetchData(search);
        },
        async doDeletePlan(plan){
            const confirm = await this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' });
            if(confirm){
                this.Overlay = true;
                fetchData(`${this.$API}/api/Seller/plan`,{
                    planId: plan.planId,
                    planSeller: plan.planSeller,
                    remove: true,
                }).then((json)=>{
                    let message = (json.msg) ? json.msg:"Server Error !!!";
                    if(json.success === true){
                        this.success(message);
                        Notice.success({message});
                        if(json.data){
                            const sellerId = this.planData['planSeller'];
                            this.doUpdatePlanDataBySellerId(sellerId, json.data);
                        }
                    }else{
                        Notice.error({message});
                        this.error(message);
                    }
                }).catch((err)=>{
                    if(err === 401){
                        this.logout();
                    }else{
                        this.error('SERVER ERROR !!!');
                    }
                }).finally(()=>{
                    this.Overlay = false;
                });
            }
        },
        doUpdatePlanDataBySellerId(seller, data){
            this.plans[seller] = data;
        },
				    doModifyPlan(){
          this.Overlay = true;
          fetchData(`${this.$API}/api/Seller/plan`,this.planData).then((json)=>{
														let message = (json.msg) ? json.msg:"Server Error !!!";
														if(json.success === true){
                  this.success(message);
																		Notice.success({message});
                  if(json.data){
                      const sellerId = this.planData['planSeller'];
                      this.doUpdatePlanDataBySellerId(sellerId, json.data);
                      this.toggleBoxPlan(false);
                  }
														}else{
                  Notice.error({message});
																		this.error(message);
														}
										}).catch((err)=>{
              if(err === 401){
                  this.logout();
              }else{
                  this.error('SERVER ERROR !!!');
              }
										}).finally(()=>{
														this.Overlay = false;
										});
				    },
				    toggleBoxPlan(item){
            if(item === false){
                item = {
                    seller_id: this.planData['planSeller'],
                }
            }
            this.stepPlan = "1";
            this.planData = {
                planSeller: item.seller_id,
                planName: "",
                planPackage: "",
                planPrice: "",
                planCountry: "Global",
                planLinked: null,
                planMultiHWID: false,
            };
            this.boxPlan = true;
				    },
				    toggleModifyPlan(plan){
            const planData = JSON.parse(JSON.stringify(plan));
            this.planData = {
                planId: planData.planId,
																planSeller: planData.planSeller,
																planName: planData.planName,
																planPackage: planData['planPackage'].toString(),
																planPrice: planData['planPrice'],
																planCountry: planData['planCountry'],
                planLinked: planData['planLinked'],
                planMultiHWID: planData['planMultiHWID'],
												};
            this.stepPlan = 2;
				    },
        async doUnlockAllKeyBySellerID(item){
            const ask = await this.$confirm(`Do you really want to unlock all key of this seller ? This data can't restore !`,{ title: 'Warning' });
            if(!ask) return;
            this.Overlay = true;
            fetchData(`${this.$API}/api/Seller/unlock-all-keys`,{
                seller_id: item['seller_id'],
            }).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    Alert.success({message:message});
                    this.fetchData();
                }else{
                    this.error(message);
                    Alert.error({message:message});
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        async doLockAllKeyBySellerID(item){
            const ask = await this.$confirm(`Do you really want to lock all key of this seller ? This data can't restore !`,{ title: 'Warning' });
            if(!ask) return;
            this.Overlay = true;
            fetchData(`${this.$API}/api/Seller/lock-all-keys`,{
                seller_id: item['seller_id'],
            }).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    Alert.success({message:message});
                    this.fetchData();
                }else{
                    this.error(message);
                    Alert.error({message:message});
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        doSelectSeller: function(item){
            this.choiceSeller = item;
            this.boxSeller = true;
        },
        searchById: function(id){
            id = parseInt(id);
            return this.sellersLists.filter(v=>v.seller_id === id);
        },
        openSM: function(item){
            let itemSeller = {price:[-1,-1,-1,-1,-1,-1]};
            if(item){
                this.itemSeller = Object.assign(itemSeller,item);
                this.modifyMode = true;
            }else{
                this.itemSeller = itemSeller;
                this.modifyMode = false;
            }
            this.updateMode = item;
            this.dialog = true;
        },
        fetchDataSystem: function(request){
            return fetchData(`${this.$API}/api/system`,request).then((json)=>{
                this.system = json;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            });
        },
        fetchSellers: function(){
            fetchData(`${this.$API}/api/Seller/getList`).then((json)=>{
                this.sellersLists = json;
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            });
        },
        fetchData: function(request){
            if(!request){
                let search = {
                    ...this.options,
                    q: this.search || undefined,
                }
                this.fetchData(search)
                return;
            }
            if(request){
                this.Overlay = true;
            }
            fetchData(`${this.$API}/api/Seller/all`,request).then((json)=>{
                if(Array.isArray(json)){
                    this.items = json;
                }
                if(json && json.total){
                    this.totalItems = json.total;
                }
                if(json && json.data){
                    this.items = json.data;
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                if(request) this.Overlay = false;
            });
        },
        doUpdateShopPrice(){
            const item = this.choiceSeller;
            this.Overlay = true;
            this.updateSellerState = true;
            fetchData(`${this.$API}/api/Seller/update_shop`,{
                seller_id: item['seller_id'],
                price: item['shop_seller'],
            }).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    Alert.success({message:message});
                    this.fetchData();
                }else{
                    this.error(message);
                    Alert.error({message:message});
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
                this.updateSellerState = false;
            });
        },
        topupSeller: function(item,method){
            this.topupMenu = true;
            this.topupSellerData = item;
            this.topupMethod = method;
        },
        doTopup: function(){
            this.topupMenu = false;
            this.Overlay = true;
            const { seller_id, credit, log } = this.topupSellerData;
            fetchData(`${this.$API}/api/Seller/${this.topupMethod}`,{ seller_id, credit, log }).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
                this.topupSellerData = {};
            });
        },
        doUpdateLink: function(item,isRemove=false){
            this.Overlay = true;
            let { seller_id, linked } = item;
            if(isRemove) {
                linked = "";
            }
            fetchData(`${this.$API}/api/Seller/link`,{ seller_id, linked }).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
                this.topupSellerData = {};
            });
        },
        doRemove2FA: function(seller_id){
            this.Overlay = true;
            fetchData(`${this.$API}/api/Seller/remove2fa`,{ seller_id}).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
                this.dialog = false;
            });
        },
        doUpdateRawHWID: function(seller){
          const {raw_hwid, seller_id} = seller;
          this.Overlay = true;
          fetchData(`${this.$API}/api/Seller/raw-hwid`,{ raw_hwid, seller_id }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
              this.success(message);
              this.fetchData();
            }else{
              this.error(message);
              seller.raw_hwid = !seller.raw_hwid;
            }
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        },
        doUpdateRefundOnRenew: function(seller){
          const {allow_refund_renew, seller_id} = seller;
          this.Overlay = true;
          fetchData(`${this.$API}/api/Seller/refund-renew`,{ allow_refund_renew, seller_id }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
              this.success(message);
              this.fetchData();
            }else{
              this.error(message);
              seller.allow_refund_renew = !seller.allow_refund_renew;
            }
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        },
        doUpdateLimitReset: function(seller){
          const {seller_id, limit_reset} = seller;
          this.Overlay = true;
          fetchData(`${this.$API}/api/Seller/limit`,{ seller_id,limit_reset}).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
              this.success(message);
              this.fetchData();
            }else{
              this.error(message);
              seller.limit_reset = !seller.limit_reset;
            }
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        },
        doUpdateLimitUnbind: function(seller){
          const {seller_id, limit_unbind} = seller;
          this.Overlay = true;
          fetchData(`${this.$API}/api/Seller/limit-unbind`,{ seller_id,limit_unbind}).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
              this.success(message);
              this.fetchData();
            }else{
              this.error(message);
              seller.limit_reset = !seller.limit_reset;
            }
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        },
        doSellerStatus: function(seller_id,status){
            this.Overlay = true;
            fetchData(`${this.$API}/api/Seller/status`,{ seller_id,status}).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
                this.dialog = false;
            });
        },
        doSellerRequest: function(){
            this.Overlay = true;
            const { seller_id, username, password, enableTopup, bonus, price, enableRefund } = this.itemSeller;
            let requestBody = {seller_id,username,password,enableTopup, bonus, price, enableRefund};
            if(!this.modifyMode){
                delete requestBody.seller_id;
            }
            let method = (this.modifyMode) ? "update":"create";
            fetchData(`${this.$API}/api/Seller/${method}`,requestBody).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
                this.dialog = false;
            });
        },
        doEnableTest(seller_id,status){
            this.Overlay = true;
            fetchData(`${this.$API}/api/Seller/testkey`,{ seller_id,status}).then((json)=>{
                let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        doFetchPlansBySeller(){
												return fetchData(`${this.$API}/api/Seller/plans`).then((json)=>{
                this.plans = json;
												},(err)=>{
																if(err === 401){
																				this.logout();
																}else{
																				this.error('SERVER ERROR !!!');
																}
												});
								},
        doFetchPlans(){
            return fetchData(`${this.$API}/api/Plans/all`).then((json)=>{
                if(Array.isArray(json.data)) {
                    this.all_plans = json.data;
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            });
        },
        doClearRemoveSeller(){
            this.remove_seller_data = {
                state: false,
                seller_id: null,
                name: null,
                remove_mode: null,
                moved_id: null,
            };
        },
        doLoadRemoveSeller(item){
            this.doClearRemoveSeller();
            this.remove_seller_data = {
                state: true,
                seller_id: item.seller_id,
                name: item.username,
                remove_mode: 1,
                moved_id: null,
            }
        },
        doUpdateMovedSellerTo(item){
            //console.log(`Remove Seller - Moved To: ${item}`);
            this.remove_seller_data.moved_id = item;
        },
        doUpdateRemoveMode(){
            if(this.remove_seller_data.remove_mode < 5)
                this.remove_seller_data.moved_id = null;
        },
        doForceLogin(seller_id){
            this.Overlay = true;
            fetchData(`${this.$API}/api/Seller/force-login`, {seller_id}).then((json)=>{
                if(json.success){
                    this.success(json.msg);
                    Alert.success({message: json.msg});
                }else{
                    this.error(json.msg);
                    Alert.error({message: json.msg});
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    this.error('SERVER ERROR !!!');
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        async doSubmitRemoveSeller(){
            const res = await this.$confirm(`Do you really want to remove this seller ? This data can't restore !`,{ title: 'Warning' });
            if(res === true){
                this.Overlay = true;
                const remove_seller_data = this.remove_seller_data;
                const body = {
                    seller_id: remove_seller_data.seller_id,
                    remove_mode: remove_seller_data.remove_mode,
                    moved_id: remove_seller_data.moved_id
                };
                fetchData(`${this.$API}/api/Seller/remove-seller`, body).then((json)=>{
                    if(json.success){
                        this.success(json.msg);
                        Alert.success({message: json.msg});
                        this.doClearRemoveSeller();
                    }else{
                        this.error(json.msg);
                        Alert.error({message: json.msg});
                    }
                },(err)=>{
                    if(err === 401){
                        this.logout();
                    }else{
                        this.error('SERVER ERROR !!!');
                    }
                }).finally(()=>{
                    this.Overlay = false;
                    return this.fetchData();
                });
            }
        },
        handleClonedCompleted(){
            this.doFetchPlansBySeller()
        }
    },
    watch: {
      options: {
        handler () {
            let search = {
                ...this.options,
                q: this.search || undefined,
            }
            this.fetchData(search)
        },
        deep: true,
      },
    },
    mounted(){
        const loader = [this.fetchDataSystem(), this.doFetchPlansBySeller(), this.doFetchPlans(), this.fetchSellers()];
        this.Overlay = true;
        Promise.all(loader).finally(()=>{
            this.Overlay = false;
        });
        LocalStorage.Load('menuSeller', this.menu);
        //this.fetchData();
    }
}
</script>
<style>
</style>
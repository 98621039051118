<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
    v-if="alert.message">
        {{ alert.message }}
    </v-alert>
          <v-layout row wrap>
          <template>
            <v-flex
              sm=12
              xs=12
              md=12
              lg=12
            >
            <v-card>
              <v-card-title>
                <v-row>
                  <v-col cols=6 lg="2" xl="1">
                    <v-btn
                      color="success"
                      dark
                      @click="doKeyManager()"
                    >
                      New Key
											<v-icon
												dark
												right
											>
												mdi-plus
											</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols=6 lg="2" xl="1">
                    <v-btn
                      color="green darken-2"
                      dark
                      @click="fetchData()"
                    >
                      Refresh
                      <v-icon
                        dark
                        right
                      >
                        mdi-refresh
                      </v-icon>
                    </v-btn>
                  </v-col>
									<v-col cols=6 lg="2" xl="2">
										<v-menu offset-y>
											<template v-slot:activator="{ on }">
												<v-btn
													:color="dataMode === 'all' ? 'blue': dataMode === 'streamer' ? 'green':'orange'"
													dark
													v-on="on"
												>
													Data Mode: {{ dataMode.toString()}}
												</v-btn>
											</template>
											<v-list>
												<v-list-item>
													<v-list-item-content>
														<v-list-item-title>
															<v-btn
																color="blue"
																@click="dataMode = 'all'"
															>
																All
															</v-btn>
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
												<v-list-item>
													<v-list-item-content>
														<v-list-item-title>
															<v-btn
																color="green"
																@click="dataMode = 'streamer'"
															>
																Stream
															</v-btn>
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
												<v-list-item>
													<v-list-item-content>
														<v-list-item-title>
															<v-btn
																color="orange"
																@click="dataMode = 'public'"
															>
																Public
															</v-btn>
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-btn
                                  color="pink"
                                  @click="dataMode = 'pending'"
                              >
                                Pending
                              </v-btn>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
											</v-list>
										</v-menu>
									</v-col>
                  <v-col cols=6 lg="2" xl="1">
                    <v-btn
                        color="orange"
                        dark
                        @click="doResetIPs()"
                    >
                      Reset IP
                      <v-icon
                          dark
                          right
                      >
                        mdi-backup-restore
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols=6 lg="2" xl="1">
                    <v-btn
                        color="orange"
                        dark
                        @click="doResetDiscord()"
                    >
                      Reset Discord
                      <v-icon
                          dark
                          right
                      >
                        mdi-backup-restore
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols=12 lg="6" xl="8">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hint="Please enter keyword and click search icon"
                      persistent-hint
                      >
                      <template v-slot:append>
                          <v-btn color="cyan" 
                              elevation="2"
                              icon
                              dark
                              :disabled="Overlay"
                              :loading="Overlay"
                              @click="fetchData()"
                          >
                              <v-icon>
                                  mdi-magnify
                              </v-icon>
                          </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols=12 lg="4" xl="4">
                    <v-autocomplete
                        v-model="filter_data.country"
                        :items="Countries"
                        label="Country"
                        clearable
                        hint="Search by Country"
                        persistent-hint
                        @change="fetchData"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="list_keys"
                :options.sync="options"
                :server-items-length="totalItems"
                item-key="key_login"
                :footer-props="{'items-per-page-options':[15,30,50,100]}"
                :items-per-page="15"
              >
                <template v-slot:[`item.country`]="{ item }">
                  {{
                    item.country ? item.country:'Global'
                  }}
                  <gb-flag
                      :code="item.country ? item.country.toString().toLowerCase():''"
                      size="mini"
                  />
                  <br />
                  <v-btn
                    class="mx-1 mb-1"
                    dark
                    color="green"
                    v-if="item.country"
                    @click="doSetCountryGlobal(item)"
                    :disabled="setCountryState"
                    :loading="item && setCountryState === item.key_id"
                    small
                  >
                    Global
                  </v-btn>
                  <v-btn
                      class="mx-1"
                      dark
                      color="blue"
                      @click="doToggleUpdateCountry(item)"
                      :disabled="setCountryState || (setCountryData.key_id !== null && setCountryData.key_id !== item.key_id)"
                      :loading="setCountryState === item.key_id"
                      small
                  >
                    Change Country
                  </v-btn>
                </template>
                <template v-slot:[`item.ips`]="{ item }">
                  <BList :items="item.ips" :flag="true" />
                  <template v-if="item.ips && item.ips.length > 0">
                    <v-btn
                      class="ma-1"
                      color="blue"
                      @click="doOpenBanIP(item.ips)"
                      block
                    >
                      <v-icon
                        light
                        left
                      >
                        mdi-hammer
                      </v-icon>
                      BAN IP
                    </v-btn>
                  </template>
                  <template v-else>

                  </template>
                </template>
                <template v-slot:[`item.discord_id`]="{ item }">
                  <BList :items="item.discord_id" :flag="false" />
                </template>
                <template v-slot:[`item.keyData`]="{ item }">
                    <v-btn
                      dark
                      text
                      small
                      v-clipboard="item.keyData"
                    >
                    <v-icon
                      light
                      left
                    >
                      mdi-check
                    </v-icon>
                    {{item.keyData}}
                    <v-icon
                      light
                      right
                    >
                      mdi-content-copy
                    </v-icon>
                    </v-btn> 
                    <v-checkbox
                        v-model="item['free_mode']"
                        label="Free Mode"
                        @change="doToggleFreeMode(item)"
                      />
                    <template v-if="item['last_status'] === 'Pending' && item['diff_hwid'] && item['diff_hwid'].length > 0">
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title>HWID Remove Details</v-list-item-title>
                          <v-list-item-subtitle>
                            <v-text-field
                              :value="item['diff_hwid'][0]['name']"
                              readonly
                              label="Name"
                              outlined
                              dark
                              dense
                            />
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-text-field
                              :value="item['diff_hwid'][0]['hwid']"
                              readonly
                              label="HWID"
                              outlined
                              dark
                              dense
                            />
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="item['diff_hwid'][0]['added']"
                          >
                            <v-text-field
                              :value="getTime(item['diff_hwid'][0]['added'])"
                              readonly
                              label="Added"
                              outlined
                              dark
                              dense
                            />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                </template>
								<template v-slot:[`item.key_bypass`]="{ item }">
									<v-checkbox
										v-model="items[item.index]['key_bypass']"
										label="Bypass System Off"
										persistent-hint
										@change="doUpdateBypass(item)"
									/>
								</template>
								<template v-slot:[`item.keyBySeller`]="{ item }">
									<template v-if="item.keyBySeller > 0">
										{{findSellerById(item.keyBySeller)['username']}}
									</template>
									<template v-else>
										NO
									</template>
								</template>
                <template v-slot:[`item.keyExpire`]="{ item }">
                  <v-btn
                    text
                    dark
                    small
                    v-if="item.keyExpire !== -1"
                  >
                  {{formatDate(item.keyExpire)}}
                  </v-btn>
                  <v-btn
                    text
                    color="success"
                    small
                    v-else
                  >
                  NEVER EXPIRE
                  </v-btn>
                </template>
                <template v-slot:[`item.username`]="{ item }">
                  <v-btn
                    text
                    color="success"
                    small
                    v-if="item.username === null"
                  >
                    ALL SELLER
                  </v-btn>
                  <v-btn
                    text
                    dark
                    small
                    v-else
                  >
                  {{ item.username }}
                  </v-btn>
                </template>
                <template v-slot:[`item.keyHWIDList`]="{ item }">
									<v-row>
										<v-col
											cols="10"
										>
                      <template v-if="dataMode !== 'pending'">
                        <template v-if="checkModeHWID(item['key_id'])">
    											<v-select
    											v-model="item.keyHWIDList"
    											:items="item.keyHWIDList"
    											label="List HWID"
    											multiple
    											@change="doUpdateHwid(item)"
    											v-if="item.keyHWIDList.length > 0 && item.keyLimitHWID !== 0"
    											small
    										>
    											<template v-slot:selection="{ index }">
    											<v-chip v-if="index === 0" x-small>
    												<span>{{ item.keyHWIDList[0] }}</span>
    											</v-chip>
    											<span
    												v-if="index === 1"
    												class="grey--text caption"
    											>
    												(+{{ item.keyHWIDList.length - 1 }} others)
    											</span>
    										</template>
    										</v-select>
                      </template>
                      <template v-else>
                        <div style="max-height: 220px; overflow-x: auto; overflow-y: auto;">
                          <v-list>
                            <v-list-item v-for="(hwid,index) in item.hwid" :key="index">
                              <v-list-item-title>Name: {{hwid.name}}</v-list-item-title>
                              <v-list-item-title>HWID: {{hwid.hwid}}</v-list-item-title>
                              <v-list-item-subtitle><v-icon right :color="getOnline(hwid) > 0 ? 'green':'red'">mdi-lightning-bolt-circle</v-icon> {{getLastOnline(hwid)}}</v-list-item-subtitle>
                            </v-list-item>
                          </v-list>
                        </div>
                      </template>
                    </template>
                      <div
                        class="ma-1"
                        v-if="item.hwid.length > 0 || item.diff_hwid.length > 0"
                      >
                        <v-btn
                          :color="checkModeHWID(item['key_id']) ? 'green':'orange'"
                          small
                          @click="checkModeHWID(item['key_id']) ? addModeHWID(item['key_id']): removeModeHWID(item['key_id'])"
                        >
                        <v-icon
                          light
                          left
                        >
                          {{ checkModeHWID(item['key_id']) ? 'mdi-pencil':'mdi-check' }} 
                        </v-icon>
                          {{ checkModeHWID(item['key_id']) ? 'Details Mode':'Edit Mode'}}
                        </v-btn>
                      </div>
											<v-btn
												color="green"
												text
												small
												v-if="item.keyHWIDList.length === 0 && item.keyLimitHWID !== 0"
											>
											<v-icon
												light
												left
											>
												mdi-check
											</v-icon>
												NO HWID
											</v-btn>
										</v-col>
										<v-col cols="2">
											<div class="mx-2 text-center">
												<v-icon
													color="green darken-2"
												>
													mdi-broadcast
												</v-icon>
												<v-chip
													color="green darken-4"
													small
												>
													{{item.online}}
												</v-chip>
											</div>
										</v-col>
                    <v-col cols="4">
                      <v-btn
                        color="green"
                        text
                        x-small
                      >
                      <template v-if="item.keyLimitHWID === 0">
                        Unlimited
                      </template>
                      <template v-else>
                        {{item.keyHWIDList.length}} / {{ item.keyLimitHWID }}
                      </template>
                      <v-icon
                        light
                        right
                      >
                        mdi-cellphone-link
                      </v-icon>
                      </v-btn> 
                    </v-col>
									</v-row>
                  <div class="ma-1" v-if="item['last_status'] === 'Pending'">
                    <div class="mb-1">
                      HWID REMOVE REQUEST
                    </div>
                    <v-btn
                      color="green"
                      small
                      @click="doApprove(item)"
                      class="mx-2"
                    >
                      Approve
                    </v-btn>
                    <v-btn
                        color="red"
                        small
                        @click="doDeny(item)"
                        class="mx-2"
                    >
                      Deny
                    </v-btn>
                  </div>
                </template>
                <template v-slot:[`item.key_id`]="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="deep-orange darken-1"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        Key Manager
                      <v-icon
                        light
                        right
                      >
                        mdi-pencil
                      </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
											<v-list-item v-if="item.keyLimitHWID !== 0">
												<v-list-item-title>
													<v-btn value="justify" color="green" text small @click="doUpdateHwid(item,true)">
														<v-icon
															light
															left
														>
															mdi-cellphone-link
														</v-icon>
														Reset HWID
													</v-btn>
												</v-list-item-title>
											</v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn value="justify" color="warning" text small @click="doDelete(item.key_id)">
                            <v-icon
                              light
                              left
                            >
                              mdi-delete
                            </v-icon>
                            Delete
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title>
                          <v-btn value="justify" color="primary" text small @click="doKeyManager(item)">
                            <v-icon
                              light
                              left
                            >
                              mdi-pencil
                            </v-icon>
                            Edit
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
            </v-data-table>
            <template v-if="dataMode === 'pending' && list_keys.length > 1">
              <div class="pa-2">
                <v-btn value="justify" color="green" class="mx-1" @click="HWIDRequest('Approved')">
                  Approved All
                </v-btn>
                <v-btn value="justify" color="red" class="mx-1" @click="HWIDRequest('Deny')">
                  Deny All
                </v-btn>
              </div>
            </template>
            </v-card>
          </v-flex></template>
          </v-layout>
    <v-overlay :value="Overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
          dark
        >
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
        <v-form ref="form" @submit.prevent="doProcess">
        <v-card>
        <v-toolbar
            color="primary"
            dark
        >PUBLICKEY MANAGER</v-toolbar>
        <v-card-text>
            <div class="text-h2 pa-12">
              <validation-provider
                v-slot="{ errors }"
                name="Public Key"
                rules="required|max:25|min:4"
              >
            <v-text-field v-model="publicKeyData.keyData" label="Public Key" :counter="25" :error-messages="errors">
                <v-icon
                    slot="prepend"
                >
                    mdi-key
                </v-icon>
            </v-text-field>
            </validation-provider>
            <validation-provider
                v-slot="{ errors }"
                name="Seller link"
                rules="required"
              >
              <v-select
                v-model="selectedSeller"
                :items="sellers"
                item-text="username"
                item-value="seller_id"
                hint="Seller Linked"
                persistent-hint
                :error-messages="errors"
                return-object
              ></v-select>
            </validation-provider>
						<v-checkbox
							v-model="publicKeyData.key_streammer"
							label="Key Streamer ?"
							v-if="!modifyMode"
						/>
						<v-select
							v-model="publicKeyData.keyBySeller"
							:items="sellersLists"
							item-text="username"
							item-value="seller_id"
							hint="Key for Seller"
							persistent-hint
							v-if="publicKeyData.key_streammer"
						/>
            <v-checkbox
              v-model="publicKeyData.keyHWIDUnlimited"
              label="NO HWID LIMIT ?"
							:disabled="publicKeyData.key_streammer"
              @change="doUpdateMinHWID"
            ></v-checkbox>
            <v-autocomplete
                v-model="publicKeyData.country"
                :items="Countries"
                label="Limit by Country ?"
                clearable
                rounded
                hint="Leave it blank if you don't want to limit by country"
                persistent-hint
            />
            <validation-provider
                v-slot="{ errors }"
                name="HWID Limit"
                rules="required"
              >
            <v-text-field
                v-model="publicKeyData.keyLimitHWID"
                label="HWID Limit"
                hint="Number of HWID Limited"
                persistent-hint
               :error-messages="errors"
                v-if="!publicKeyData.keyHWIDUnlimited"
               >
            </v-text-field>
            </validation-provider>
            <v-checkbox
              v-model="publicKeyData.keyUnlimited"
              label="NEVER EXPIRE ?"
              @change="doUpdateTime"
            ></v-checkbox>
            <template v-if="!publicKeyData.keyUnlimited">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="publicKeyData.keyExpire"
                    label="Expire Date"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="publicKeyData.keyExpire"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </template>
            <v-checkbox
                v-model="publicKeyData.sessions"
                label="SESSIONS CHECK ?"
            />
            <v-btn-toggle borderless>

            <v-btn value="left" color="primary" type="submit" v-if="!modifyMode" :disabled="invalid">
                <span class="hidden-sm-and-down">Create</span>
                <v-icon right>
                mdi-plus
                </v-icon>
            </v-btn>

            <v-btn value="left" color="primary" type="submit" v-if="modifyMode" :disabled="invalid">
                <span class="hidden-sm-and-down">Update</span>
                <v-icon right>
                mdi-pencil
                </v-icon>
            </v-btn>
            </v-btn-toggle>
            <div v-if="errorSubmit">
              <br />
              <v-alert
                text
                prominent
                type="error"
                icon="mdi-cloud-alert"
                timeout="5000"
              >
                {{ errorSubmit }}
              </v-alert>
            </div>
            </div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn
            text
            @click="dialog = false"
            >Close</v-btn>
        </v-card-actions>
        </v-card>
        </v-form>
          </validation-observer>
        </v-dialog>
    <v-dialog
      v-model="boxCountry"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline">Change Country</v-card-title>
        <v-card-text>
          <v-autocomplete
              v-model="setCountryData.country"
              :items="Countries"
              label="Limit by Country ?"
              clearable
              rounded
              hint="Leave it blank if you don't want to limit by country"
              persistent-hint
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="green"
              @click="doUpdateCountry"
              :disabled="setCountryState"
              :loading="setCountryState === setCountryData.key_id"
              small
              class="mt-1 mb-1"
          >
            Update
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="doCancelUpdateCountry">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <BanIP ref="BanIP" />
  </v-container>
</template>

<script>
import { fetchData } from '../helpers';
import { mapState, mapActions } from 'vuex'
import { clipboard } from 'vue-clipboards';
import { required, digits, min, max, regex } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import CountryJson from "../json/countries.json";
import BanIP from "../components/BanIP.vue";
import BList from "../components/List.vue";
import Notice from '../helpers/notice';
setInteractionMode('eager')
  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })
  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })
  extend('min', {
    ...min,
    message: '{_field_}  must be {length} or more',
  })
  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })
  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })
export default {
  name: 'PublicKey',
  directives: { clipboard },
  components: {
    ValidationObserver,
    ValidationProvider,
    BanIP,
    BList,
  },
  computed: {
      ...mapState('auth', ['status']),
			...mapState({
					alert: state => state.alert
			}),
      Countries(){
        return Object.keys(CountryJson).map((key) => {
          return {
            value: key,
            text: CountryJson[key]
          };
        });
      },
			list_keys(){
				// return this.items.map((item,index)=>({
				// 	...item,
				// 	index,
				// })).filter(f=>this.dataMode === 'all' || (this.dataMode === 'streamer' && f['key_streammer']) || (this.dataMode === 'public' && !f['key_streammer']) || (this.dataMode === 'pending' && f['last_status'] === 'Pending')).filter(f=>this.filter_data.country === null || f.country === this.filter_data.country);
        return this.items.map((item,index)=>({
					...item,
					index,
				}));
			}
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    getTime(unixTime){
      const date = new Date(unixTime * 1000);
      return date.toLocaleString();
    },
    doOpenBanIP(ips){
      const list_ip = ips.map(ip=>{
        const ip_details = ip.split(':');
        return {
          address: ip_details[0],
          country: ip_details[1],
        }
      });
      this.$refs.BanIP.openModal(list_ip);
    },
    getOnline(item){
      const timeDifferenceInSeconds = this.getTimeDiff(item);
      if (timeDifferenceInSeconds <= 300 && timeDifferenceInSeconds !== -1 || timeDifferenceInSeconds == NaN) return true;
      return false;
    },
    getTimeDiff(item){
      if(item.last_online === -1) return -1;
      const dateObj = new Date(item.last_online*1000);
      const currentTime = new Date();
      const timeDifferenceInSeconds = Math.floor((currentTime-dateObj)/1000);
      return timeDifferenceInSeconds;
    },
    getLastOnline(item){
      if(item.last_online === -1){
        return "Offline 3days ago";
      }else{
        const dateObj = new Date(item.last_online*1000);
        const timeDifferenceInSeconds = this.getTimeDiff(item);
        if (timeDifferenceInSeconds > 0){
          if(timeDifferenceInSeconds < 3600){
            const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
            if(minutesAgo === 0){
              return `Playing`;
            }
            return `${minutesAgo} mins ago`;
          }
          if(timeDifferenceInSeconds < 86400){
              // Less than 24 hours ago
              const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
              return `${hoursAgo} hours ago`;
          }
        }
        return dateObj.toLocaleString();
      }
    },
    async HWIDRequest(Action){
      const res = await this.$confirm(`Do you really want to ${Action} all hwid request ? This data can't restore !`,{ title: 'Warning' })
      if(res !== true) return
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/hwid-request`,{
        cmd:Action,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    async doResetIPs(){
      const res = await this.$confirm(`Do you really want to reset ? This data can't restore !`,{ title: 'Warning' })
      if(res !== true) return
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/reset-ips`,{
        reset:true,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    async doResetDiscord(){
      const res = await this.$confirm(`Do you really want to reset ? This data can't restore !`,{ title: 'Warning' })
      if(res !== true) return
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/reset-discord`,{
        reset:true,
      }).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doCancelUpdateCountry(){
      this.boxCountry = false;
      this.setCountryData = {
        key_id: null,
        country: null,
      }
    },
    doToggleUpdateCountry(item){
      this.setCountryData = {
        key_id: item.key_id,
        country: item.country,
      };
      this.boxCountry = true;
    },
    doUpdateCountry(){
      const item = this.setCountryData;
      this.setCountryState = item.key_id;
      fetchData(`${this.$API}/api/PublicKey/country`,{
        key_id: item.key_id,
        country: item.country,
      }).then((json)=>{
        const {success, msg} = json;
        let message = (msg) ? msg:"Server Error !!!";
        if(success){
          this.doCancelUpdateCountry();
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.setCountryState = null;
      });
    },
    doSetCountryGlobal(item){
      this.setCountryState = item.key_id;
      fetchData(`${this.$API}/api/PublicKey/country`,{
        key_id: item.key_id,
        country: '',
      }).then((json)=>{
        const {success, msg} = json;
        let message = (msg) ? msg:"Server Error !!!";

        if(success){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.setCountryState = null;
      });
    },
    fetchData: function(){
      let request = {
          ...this.options,
          q: this.search || undefined,
          mode: this.dataMode,
          country: this.filter_data.country || undefined,
      };
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/all`,request).then((json)=>{
        if(Array.isArray(json)){
            this.items = json;
        }
        if(json && json.total >= 0 ){
            this.totalItems = json.total;
        }
        if(json && json.data){
            this.items = json.data;
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    clean(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "All") {
          delete obj[propName];
        }
      }
      return obj
    },
    parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
		doUpdateBypass: function(item){
			this.Overlay = true;
			fetchData(`${this.$API}/api/PublicKey/bypass`,{
				key_id:item['key_id'],
				status:!item['key_bypass'],
			}).then((json)=>{
				let message = (json.msg) ? json.msg:"Server Error !!!";
				if(json.success == true){
					this.success(message);
					this.fetchData();
				}else{
					this.error(message);
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error('SERVER ERROR !!!');
				}
			}).finally(()=>{
				this.Overlay = false;
			});
		},
    fetchSellers: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Seller/getList`).then((json)=>{
        this.sellers = this.sellers.concat(json);
				this.sellersLists = json;
      },(err)=>{
        if(err === 401){
          this.logout();  
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doApprove: function(item){
      let request = {};
      if(item){
        const { key_id } = item;
        request = { key_id };
      }else{
        this.error("NO PUBLIC KEY SELECTED !!!");
        return;
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/approve`,request).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doDeny: function(item){
      let request = {};
      if(item){
        const { key_id } = item;
        request = { key_id };
      }else{
        this.error("NO PUBLIC KEY SELECTED !!!");
        return;
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/deny`,request).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
          this.success(message);
          this.fetchData();
        }else{
          this.error(message);
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doUpdateHwid: function(item,reset=false){
      let request = {};
      let new_data = [];
      if(item){
        const { key_id, keyHWIDList } = item;
        keyHWIDList.forEach(data => {
          if(data.hwid && data.name){
            new_data.push(data)
          }else{
            const split_data = data.split("-")
            if(split_data.length === 2){
              new_data.push({
                name: split_data[0].trim(),
                hwid: split_data[1].trim(),
              })
            }else{
              new_data.push(data)
            }
          }
        })
        request = reset ? { key_id }:{ key_id, keyHWIDList: new_data };
      }else{
        this.error("NO PUBLIC KEY SELECTED !!!");
        return;
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/hwid`,request).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
            this.success(message);
            this.fetchData();
        }else{
            this.error(message);
        }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doCopyKey: function(item){
      if(item.key_status === 1){
          return item.key_login;
      }
    },
    doParseTime: function(data){
      var dt = new Date(data*1000);
      return dt.toLocaleString();
    },
    doUpdateTime: function(){
        if(this.publicKeyData.keyUnlimited === false){
          if(this.publicKeyData.keyExpire === -1){
            this.publicKeyData.keyExpire = new Date().toISOString().substr(0, 10);
          }
        }else{
          this.publicKeyData.keyExpire = -1;
        }
    },
    doUpdateMinHWID: function(val){
      if(val === true){
        this.minHWIDLimit = 0;
      }else{
        this.minHWIDLimit = this.publicKeyData.keyHWIDList.length;
      }
    },
    doToggleFreeMode: function(item){
      const request = {
        key_id: item.key_id,
        free_mode: item.free_mode,
      };
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/freemode`,request).then((json)=>{
        let message = (json.msg) ? json.msg:"Server Error !!!";
        if(json.success == true){
            this.success(message);
            Notice.success({message});
        }else{
            item.free_mode = !item.free_mode;
            this.error(message);
            Notice.error({message});
        }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    doKeyManager: function(item){
      this.errorSubmit = "";
      let newData = {};
      if(item){
        this.modifyMode = true;
        newData.keyHWIDUnlimited = (item.keyLimitHWID === 0) ? true:false;
        this.selectedSeller = {seller_id:item.keySeller,username:item.username};
        console.log(item);
        this.publicKeyData = Object.assign(newData,item);
        this.minHWIDLimit = newData.keyHWIDUnlimited ? 0:item.keyHWIDList.length;
      }else{
        this.modifyMode = false;
        newData = {keyUnlimited:true,keyExpire:-1,seller_id:0,keyHWIDUnlimited:false,keyLimitHWID:0,keyHWIDList:[]};
        this.publicKeyData = newData;
        this.minHWIDLimit = 0;
        this.selectedSeller = {seller_id:0,username:"All"};
      }
      this.dialog = true;
    },
    doProcess: function(){
      let method = (this.modifyMode) ? "update":"add";
      let requestData = Object.assign({},this.publicKeyData);
      requestData.keyLimitHWID = (requestData.keyHWIDUnlimited === true) ? 0:requestData.keyLimitHWID;
			if(requestData['key_streammer']){
				if(requestData['keyLimitHWID'] <= 0){
					this.errorSubmit = `HWID Limit need to more than 0 When Key Streammer Activated !!!`;
					return;
				}
				if(!requestData['keyBySeller']){
					this.errorSubmit = `You need to select Seller When Key Streammer Activated !!!`;
					return;
				}
			}
      requestData.keySeller = this.selectedSeller.seller_id;
      delete requestData['username'];
      delete requestData['keyUnlimited'];
      delete requestData['keyHWIDUnlimited'];
      delete requestData['keyHWIDList'];
      if(requestData.keyLimitHWID < this.minHWIDLimit){
        this.errorSubmit = `HWID Limit need to more than ${this.minHWIDLimit}`;
        return;
      }
      this.Overlay = true;
      fetchData(`${this.$API}/api/PublicKey/${method}`,requestData).then((json)=>{
          let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
                this.success(message);
                this.fetchData();
            }else{
                this.error(message);
            }  
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
        this.dialog = false;
      });
    },
    doDelete: function(key_id){
      this.Overlay = true;
      this.$confirm(`Do you really want to delete ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/PublicKey/delete`,{ key_id },'DELETE').then((json)=>{
              let message = (json.msg) ? json.msg:"Server Error !!!";
                if(json.success == true){
                    this.success(message);
                    this.fetchData();
                }else{
                    this.error(message);
                }  
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
            this.dialog = false;
          });
        }else{
					this.Overlay = false;
				}
      });
    },
    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
		findSellerById(id){
			let seller = {};
			this.sellersLists.forEach(item=>{
				if(item.seller_id === id) seller = item;
			})
			return seller;
		},
    addModeHWID(id){
      if(!this.hwid_mode_byid.includes(id)){
        this.hwid_mode_byid.push(id);
      }
    },
    removeModeHWID(id){
      if(this.hwid_mode_byid.includes(id)){
        this.hwid_mode_byid.splice(this.hwid_mode_byid.indexOf(id),1);
      }
    },
    checkModeHWID(id){
      return !this.hwid_mode_byid.includes(id);
    },
  },
  data : () => ({
    totalItems: 0,
    options: {},
    filter_data: {
      country: null,
    },
    hwid_mode: 'edit',
    hwid_mode_byid: [],
    boxCountry: false,
    setCountryData:{
      key_id: null,
      country: null,
    },
    setCountryState: null,
		dataMode: 'all',
    selectedSeller: {seller_id:0,username:"All"},
    minHWIDLimit: 0,
    menu1: false,
    publicKeyData: {
      sessions: 0,
    },
    modifyMode:false,
    extraTimeKey: "",
    Overlay: false,
    dialog: false,
    search: '',
    errorSubmit: "",
    invalid: true,
    headers: [
            { text: 'Key', align: 'right', sortable: false,value: 'keyData',width:"auto" },
            { text: 'Expire', align: 'right', sortable: true,value: 'keyExpire',width:"auto"},
            { text: 'HWID', align: 'left', sortable: false,value: 'keyHWIDList',width:"auto" },
            { text: 'Discord', align: 'center', sortable: false,value: 'discord_id',width:"auto" },
            { text: 'IP', align: 'left', sortable: false,value: 'ips',width:"auto" },
            { text: 'Country', align: 'center', sortable: false,value: 'country',width:"auto" },
            { text: 'Seller', align: 'left', sortable: false,value: 'username',width: "auto"},
						{ text: 'Streamer', align: 'left', sortable: false,value: 'keyBySeller',width: "auto"},
            { text: 'Played Time', align: 'center', sortable: false,value: 'playedTime',width: "auto"},
            { text: 'Win', align: 'center', sortable: false,value: 'wingame',width: "auto"},
						{ text: 'System Off', align: 'center', sortable: false,value: 'key_bypass',width: "auto"},
            { text: 'Modify', align: 'center', sortable: false,value: 'key_id',width: "auto" },
        ],
    items: [],
    sellers: [{seller_id:0,username:"All"}],
		sellersLists: [],
  }),
  watch:{
    '$route.query.system': function(newValue, oldValue) {
      console.log(newValue);
      if(newValue !== oldValue && !newValue){
        this.dataMode = "all";
      }else{
        this.dataMode = this.$route.query.system.toString();
      }
    },
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
    dataMode: {
      handler () {
        this.fetchData()
      },
      deep: true,
    }
  },
  mounted(){
    if(this.$route.query.system) this.dataMode = this.$route.query.system.toString();
    else this.dataMode = "all";
    // this.fetchData();
    this.fetchSellers();
  }
}
</script>
<style>
</style>